import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import CSS cho DatePicker
import "./index.css";

// Các imports khác
import Grid from "@mui/material/Grid";
import { Box, Button, TextField, Pagination, Typography } from "@mui/material";
import { formattedDateSoulPlan } from "utils/helpers";
import CardStudent from "./components/CardStudent";
import { Cached } from "@mui/icons-material";
import { getSoulPlan } from "services/matrix.service";
import CreateForm from "./components/CreateForm";
import { getFromLocalStorage } from "utils/local-storage";
import { USER_INFO } from "constant/AuthConstant";
import { ADMIN } from "constant/AuthConstant";
import { toast } from "react-toastify";

const Matrix = () => {
  const [open, setOpen] = useState(false);
  const [soulPlanData, setSoulPlanData] = useState({ data: [] });
  const [pagination, setPagination] = useState({
    per_page: 12,
    page: 1,
    total_pages: 1,
    fullName: "",
    birthday: "",
    creator_name: "",
  });

  const userInfo = getFromLocalStorage(USER_INFO);

  const { handleSubmit, control, reset } = useForm();

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const onSearch = async (data) => {
    const { fullName, birthday, creator_name } = data;
    const formattedDate = birthday ? formattedDateSoulPlan(birthday) : "";
    try {
      const response = await getSoulPlan({
        ...pagination,
        fullName,
        birthday: formattedDate,
        creator_name: creator_name,
      });

      setSoulPlanData(response?.data);
      setPagination((prev) => ({
        ...prev,
        fullName,
        birthday: formattedDate,
        creator_name: creator_name,
        total_pages: response?.data?.total_pages,
      }));
    } catch (error) {
      toast.error("Đã có lỗi xảy ra!");
    }
    handleClose();
  };

  useEffect(() => {
    callApiGetData();
  }, [pagination.page]);

  const handlePageChange = (event, value) => {
    setPagination((prev) => ({ ...prev, page: value }));
  };

  const callApiGetData = async () => {
    try {
      const response = await getSoulPlan(pagination);
      setSoulPlanData(response?.data);
      setPagination((prev) => ({
        ...prev,
        total_pages: response?.data?.total_pages,
      }));
    } catch (error) {
      toast.error("Đã có lỗi xảy ra!");
    }
  };

  const handleClear = () => {
    callApiGetData();
    reset();
  };

  return (
    <>
      {/* Dialog create report */}
      <CreateForm
        open={open}
        handleClose={handleClose}
        setExportData={setSoulPlanData}
        soulPlanData={soulPlanData}
      />
      <Box className="mt-4 flex justify-between gap-2">
        <Box className="w-2/5">
          <Controller
            name="fullName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Tìm theo họ và tên"
                variant="outlined"
                size="small"
                // className="w-2/5"
              />
            )}
          />
        </Box>
        <Box className="w-2/5">
          <Controller
            name="birthday"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                selected={field.value ? new Date(field.value) : null}
                onChange={(date) => field.onChange(date)}
                dateFormat="dd/MM/yyyy"
                placeholderText="Chọn ngày sinh"
                className="bg-transparent h-10 w-2/5 rounded-md border border-gray-300 px-3"
              />
            )}
          />
        </Box>
        {userInfo?.user?.role === ADMIN ? (
          <Box className="w-2/5">
            <Controller
              name="creator_name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Tìm theo họ và tên người tạo"
                  variant="outlined"
                  size="small"
                  // className="w-2/5"
                />
              )}
            />
          </Box>
        ) : null}
        <Button
          variant="outlined"
          onClick={handleSubmit(onSearch)}
          className="w-1/5 border-primary normal-case text-primary"
        >
          Tìm kiếm
        </Button>
        <Button
          variant="outlined"
          onClick={handleClear}
          className="w-1/5 border-primary normal-case text-primary"
        >
          <Cached /> Tải lại
        </Button>
      </Box>

      <Box>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Box className="-mb-4 mt-6">
              <Box className="flex items-center justify-between">
                <Button
                  className=" normal-case text-primaryPurple"
                  sx={{ border: "1px solid #a881fe" }}
                  variant="outlined"
                  onClick={handleClickOpen}
                >
                  Xuất báo cáo
                </Button>

                <Typography className="font-bold">
                  {soulPlanData?.total_items} Báo cáo
                </Typography>
              </Box>
            </Box>
          </Grid>
          {soulPlanData?.data && soulPlanData?.data.length > 0 ? (
            soulPlanData?.data.map((item, index) => (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <CardStudent data={item} />
              </Grid>
            ))
          ) : (
            <Grid item xs={12} sm={6} md={4}>
              <p>Không tìm thấy vận mệnh linh hồn</p>
            </Grid>
          )}
        </Grid>
      </Box>

      {/* Pagination */}
      <Box className="mt-16">
        <Grid container spacing={6} justifyContent="center" className="mt-8">
          <Pagination
            count={pagination.total_pages}
            page={pagination.page}
            onChange={handlePageChange}
          />
        </Grid>
      </Box>
    </>
  );
};

export default Matrix;
