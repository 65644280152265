import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import { MdBarChart, MdHome, MdLock } from "react-icons/md";
import SoulPlan from "views/admin/soul-plan";
import DownloadReport from "views/admin/soul-plan/components/DownloadReport";
import UserTable from "views/admin/user";
import Images from "constant/Images";
import Matrix from "views/admin/matrix";
import DownloadReportMatrix from "views/admin/matrix/components/DownloadReport";

const routes = [
  {
    name: "Thông tin user",
    layout: "/admin",
    path: "default",
    icon: (
      <img className="h-6 w-6" src={Images.iconSixPointedStar} alt="six star" />
    ),
    component: <MainDashboard />,
  },

  {
    name: "Soul Plan Personal",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "soul-plan",
    component: <SoulPlan />,
  },
  {
    name: "Matrix Of Destiny",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "matrix",
    component: <Matrix />,
  },
  {
    name: "User",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "user",
    component: <UserTable />,
  },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Pdf Preview",
    layout: "/blank",
    path: "view",
    component: <DownloadReport />,
  },
  {
    name: "Pdf Preview",
    layout: "/blank",
    path: "view/:nodownload",
    component: <DownloadReport />,
  },
  {
    name: "Pdf Preview",
    layout: "/matrix",
    path: "view",
    component: <DownloadReportMatrix />,
  },
  {
    name: "Pdf Preview",
    layout: "/matrix",
    path: "view/:nodownload",
    component: <DownloadReportMatrix />,
  },
];
export default routes;
