// import axios from 'axios'

import { fetchClient } from "api/app.service";
import { SOUL_PLANS } from "constant/Api";
import { MATRIX } from "constant/Api";

export const getSoulPlan = async (param) => {
  return fetchClient(true)
    .get(`${SOUL_PLANS}`, { params: param })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export const createSoulPlan = async (data) => {
  return fetchClient(true)
    .post(`${SOUL_PLANS}`, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export const getMatrix = async (param) => {
  return fetchClient(true)
    .get(`${MATRIX}`, { params: param })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export const createMatrix = async (data) => {
  return fetchClient(true)
    .post(`${MATRIX}`, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};
