// MUI Imports
import { Box, Card, CardContent, Typography, Grid } from "@mui/material";

// Components Imports
import Images from "constant/Images";
import TrainingLevels from "./components/TrainingLevels";
import CardToolsExport from "./components/CardToolsExport";
import { SOUL_PLAN_PERSONAL } from "constant/LevelConstant";
import { SOUL_PLAN_GROUP } from "constant/LevelConstant";
import { MATRIX_OF_DESTINY } from "constant/LevelConstant";
import { SOUL_PLAN_PERSONAL_URL } from "constant/Api";
import { SOUL_PLAN_GROUP_URL } from "constant/Api";
import { MATRIX_OF_DESTINY_URL } from "constant/Api";
import { getFromLocalStorage } from "utils/local-storage";
import { USER_INFO } from "constant/AuthConstant";

const dataCardExportReport = [
  {
    title: "Soul Plan Personal",
    thumbnail: Images.iconSixPointedStar,
    path: `admin${SOUL_PLAN_PERSONAL_URL}`,
    key: SOUL_PLAN_PERSONAL,
  },
  {
    title: "Soul Plan Group",
    thumbnail: Images.group,
    path: `admin${SOUL_PLAN_GROUP_URL}`,
    key: SOUL_PLAN_GROUP,
  },
  {
    title: "Matrix Of Destiny",
    thumbnail: Images.matrix,
    path: `admin${MATRIX_OF_DESTINY_URL}`,
    key: MATRIX_OF_DESTINY,
  },
];

const Dashboard = () => {
  const user_info = getFromLocalStorage(USER_INFO);
  return (
    <Grid container spacing={6}>
      <Grid item xs={12} lg={12}>
        <TrainingLevels />
      </Grid>

      <Grid item xs={12} lg={12}>
        <Typography variant="h5" className="text-primary">
          Công cụ xuất Báo cáo
        </Typography>
      </Grid>

      {dataCardExportReport.map((item, index) =>
        user_info?.user?.level.indexOf(item?.key) >= 0 ? (
          <Grid key={index} item xs={6} md={4} lg={4}>
            <CardToolsExport
              thumbnail={item?.thumbnail}
              path={item?.path}
              title={item?.title}
            />
          </Grid>
        ) : null
      )}
    </Grid>
  );
};

export default Dashboard;
