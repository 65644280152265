import React, { useState } from "react";
import Dropdown from "components/dropdown";
import { Link, useNavigate } from "react-router-dom";

// import { FiSearch } from "react-icons/fi";
// import { RiMoonFill, RiSunFill } from "react-icons/ri";

import { removeFromCookieStorage } from "utils/cookie";
import { AUTHENTICATION } from "constant/AuthConstant";
import { removeFromLocalStorage } from "utils/local-storage";
import { USER_INFO } from "constant/AuthConstant";
import { FiAlignJustify } from "react-icons/fi";
import { getFromLocalStorage } from "utils/local-storage";
import { Avatar } from "@mui/material";
import { uploadFileToStorage } from "utils/helpers";
import { toast } from "react-toastify";
import { AVATAR_FOLDER } from "constant/Api";
import { editUser } from "services/user.service";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "store/userReducer";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Navbar = (props) => {
  const { brandText, onOpenSidenav } = props;

  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  // const [darkmode, setDarkmode] = React.useState(false);
  let user_info = getFromLocalStorage(USER_INFO);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [userInfo, setUserInfo] = useState(user_info);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    removeFromLocalStorage(USER_INFO);
    removeFromCookieStorage(AUTHENTICATION);
    navigate("/auth/sign-in");
  };

  const handleChooseFile = async (event) => {
    const max_file_size = 4 * 1024 * 1024; // 4MB
    // setUploadFile(true);
    if (event.target.files) {
      const file = event.target.files[0];
      if (file.size > max_file_size) {
        toast.error(
          `Kích thước file quá lớn. Tối đa ${max_file_size / 1024 / 1024}MB.`
        );

        event.target.value = "";

        return false;
      }
      setSelectedImage(URL.createObjectURL(file));
      setSelectedFile(file);
    } else {
      toast.error(`Không tìm thấy file.`);
    }
  };

  const handleUploadFile = async (event) => {
    try {
      const storageLink = await uploadFileToStorage(
        selectedFile,
        "avatar",
        AVATAR_FOLDER
      );

      const data = {
        avatar: storageLink,
      };

      const response = await editUser({
        data,
        userId: user_info?.user?.id,
      });
      if (response.status === 200) {
        setUserInfo(response.data.data);
        dispatch(setUser(response.data.data));
        setOpen(false);
        toast.success(`Cập nhật ảnh đại diện thành công`);
      }
      event.target.value = "";

      return true;
    } catch (error) {
      toast.error(`Đã có lỗi xảy ra !`);
    }
  };

  return (
    <>
      <nav className="sticky top-0 z-40 flex w-full flex-row flex-wrap items-center justify-between rounded-xl bg-white p-2  dark:bg-[#0b14374d]">
        <div className="ml-[6px]">
          <div className="h-6 w-[224px] pt-1">
            <a
              className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
              href=" "
            >
              Pages
              <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
                {" "}
                /{" "}
              </span>
            </a>
            <Link
              className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
              to="#"
            >
              {brandText}
            </Link>
          </div>
          <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
            <Link
              to="#"
              className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
            >
              {brandText}
            </Link>
          </p>
        </div>

        <div className="relative mt-[3px] flex h-[61px] w-[100px] items-center justify-around gap-2 rounded-[5px] bg-white px-2 py-2  dark:!bg-navy-800 dark:shadow-none  md:flex-grow-0 md:gap-1 xl:gap-2">
          {/* <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
            <p className="pl-3 pr-2 text-xl">
              <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
            </p>
            <input
              type="text"
              placeholder="Search..."
              className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
            />
          </div> */}
          <span
            className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
            onClick={onOpenSidenav}
          >
            <FiAlignJustify />
          </span>

          {/* Profile & Dropdown */}
          <Dropdown
            button={
              userInfo?.user?.avatar ? (
                <Avatar
                  src={userInfo?.user?.avatar}
                  className="rou h-10 w-10"
                  alt="avatar"
                />
              ) : (
                <Avatar src={user?.avatar} className="h-10 w-10" />
              )
            }
            children={
              <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                <div className="p-4">
                  <div className="flex items-center gap-2">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      👋 Hi, {user?.name}
                    </p>{" "}
                  </div>
                </div>
                <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />
                <div className="flex flex-col px-4 py-2">
                  <div
                    onClick={(e) => handleClickOpen(e)}
                    className="mt-3 cursor-pointer text-sm font-medium transition duration-150 ease-out  hover:text-purple-700 hover:ease-in"
                  >
                    Đổi ảnh đại diện
                  </div>
                </div>

                <div className="flex flex-col px-4 py-2">
                  <div
                    onClick={(e) => handleLogout(e)}
                    className="mt-1 cursor-pointer text-sm font-medium text-red-500 transition duration-150 ease-out hover:text-red-500 hover:ease-in"
                  >
                    Đăng xuất
                  </div>
                </div>
              </div>
            }
            classNames={"py-2 top-8 -left-[180px] w-max"}
          />
        </div>
      </nav>
      <React.Fragment>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Chọn ảnh đại diện"}</DialogTitle>
          <DialogContent>
            {/* Input để chọn ảnh */}
            <div className="flex h-[100px] w-[300px] items-center justify-center space-y-4 border border-dashed border-purple-500">
              <input
                accept="image/*"
                type="file"
                onChange={handleChooseFile}
                className="hidden"
                id="file-input"
              />
              <label
                htmlFor="file-input"
                className="mt-0 cursor-pointer rounded  text-purple-500 transition-all"
              >
                Chọn ảnh
              </label>

              {selectedImage && (
                <div className="mt-4">
                  <img
                    src={selectedImage}
                    alt="Selected"
                    className="ml-2 max-h-10 max-w-full rounded object-cover shadow-lg"
                  />
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              className="border border-purple-500 text-purple-500"
              onClick={handleClose}
            >
              Trở lại
            </Button>
            <Button
              className="bg-purple-500 text-white"
              onClick={handleUploadFile}
            >
              Lưu
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </>
  );
};

export default Navbar;
