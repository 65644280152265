import React from "react";
import { Button, DialogTitle, DialogContentText } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

const DeleteForm = ({ open, handleClose, handleDeleteUser }) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Xác nhận xóa"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-delete-dialog">
            Bạn có chắc chắn xóa người dùng này?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Hủy bỏ</Button>
          <Button onClick={handleDeleteUser} autoFocus>
            Đồng Ý
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteForm;
