import React from "react";
import { Routes, Route } from "react-router-dom";
import routes from "routes.js";

export default function Blank(props) {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/blank") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  return (
    <div className="flex h-full w-full">
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-white ">
        {/* Main Content */}
        <main className={`mx-[12px] h-full flex-none transition-all `}>
          {/* Routes */}
          <div className="h-full">
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] ">
              <Routes>{getRoutes(routes)}</Routes>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
