import React, { useEffect, useState } from "react";
import Images from "constant/Images";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { capitalizeName } from "utils/helpers";
import { getYearOfBirth } from "utils/helpers";
import { HOST } from "constant/Api";
import { FILE } from "constant/Api";
import { PROXY_IMAGE } from "constant/Api";
import { toast } from "react-toastify";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

// Constants for chakra display names and colors
const CHAKRA_DISPLAY_NAMES = {
  sahasrasa: ["Sahasrara (Vương niệm)", "Sứ mệnh"],
  ajna: ["Ajna (Con mắt thứ 3)", "Vận mệnh", "Tâm linh huyền bí"],
  vissudha: ["Vissudha (Cổ họng)", "Vận mệnh", "Tâm linh huyền bí"],
  anahata: ["Anahata (Tim)", "Mối quan hệ", "Bức tranh của thế giới"],
  manipura: ["Manipura (Đám rối mặt trời", "Địa vị, chức vụ"],
  svadhisthana: [
    "Svadhisthana (xương cùng)",
    "Tình yêu đối với trẻ con",
    "Niềm vui",
  ],
  muladhara: ["Muladhara (Gốc)", "Cơ thể vật lý", "Vật chất"],
};
const RAINBOW_COLORS = [
  "bg-[#6c37ab]",
  "bg-[#526cfd]",
  "bg-[#4acedd]",
  "bg-[#87e12a]",
  "bg-[#fcee0f]",
  "bg-[#ffa800]",
  "bg-[#f80300]",
];

// Reusable Chakra Table Row Component
const ChakraRow = ({ chakra, index, data }) => {
  const rowColor = RAINBOW_COLORS[index % RAINBOW_COLORS.length];
  const displayLines = CHAKRA_DISPLAY_NAMES[chakra] || [chakra];

  return (
    <tr className={index % 2 === 0 ? "bg-[#f9f6f6]" : "bg-[#faf9f9]"}>
      <td
        className={`border px-4 py-1 text-center text-xs font-semibold text-[#000] ${rowColor}`}
      >
        {7 - index}
      </td>
      <td className="border  px-2 py-1  text-xs">
        {displayLines.map((line, lineIndex) => (
          <div
            key={lineIndex}
            className={
              lineIndex === 0
                ? "py-[6px] text-[12px] font-bold text-[#000]"
                : "text-[10px] text-[#8d9399]"
            }
          >
            {line}
          </div>
        ))}
      </td>
      <td className="border py-1  text-center text-xs font-semibold text-[#000]">
        {data.material}
      </td>
      <td className="border py-1  text-center text-xs font-semibold text-[#000]">
        {data.thinking}
      </td>
      <td className="border py-1  text-center text-xs font-semibold text-[#000]">
        {data.feeling}
      </td>
    </tr>
  );
};

// Chakra Table Component
const ChakraTable = ({ chakras, data }) => (
  <table className="min-w-full table-auto border-collapse">
    <thead>
      <tr className="bg-[#faf9f9]">
        <th className="border py-1 text-xs text-[#a881fe]"></th>
        <th className="border py-1 text-xs text-[#a881fe]">Tên luân xa</th>
        <th className="border py-1 text-xs text-[#a881fe]">Vật chất</th>
        <th className="border py-1 text-xs text-[#a881fe]">Tư Duy</th>
        <th className="border py-1 text-xs text-[#a881fe]">Cảm xúc</th>
      </tr>
    </thead>
    <tbody>
      {chakras.map((chakra, index) => (
        <ChakraRow
          key={chakra}
          chakra={chakra}
          index={index}
          data={data[chakra]}
        />
      ))}
      <tr className="bg-[#faf9f9] font-bold">
        <td className="border px-4 py-1 text-[#000] "></td>
        <td className="border px-2  py-1 text-[#000] ">
          <div className={"py-2 text-[12px] font-bold text-[#000]"}>
            Kết quả
          </div>
          <div className={"text-[10px] font-normal text-[#8d9399]"}>
            Vùng năng lượng phổ biển
          </div>
        </td>
        <td className="border px-4 py-1 text-center text-xs font-semibold text-[#000]">
          {data.total.material}
        </td>
        <td className="border px-4 py-1 text-center text-xs font-semibold text-[#000]">
          {data.total.thinking}
        </td>
        <td className="border px-4 py-1 text-center text-xs font-semibold text-[#000]">
          {data.total.feeling}
        </td>
      </tr>
    </tbody>
  </table>
);

const DialogReport = ({
  open,
  handleClose,
  user_info,
  data,
  handleDownloadPdf,
}) => {
  const { user } = useSelector((state) => state.user);
  const matrixData = data?.data || {};
  const [userInfo, setUserInfo] = useState(null);

  // Load user info from local storage or some other source asynchronously
  useEffect(() => {
    if (user && user.avatar) {
      setUserInfo(user); // Set the user info once available
    } else {
      toast.warning(
        `Bạn phải cập nhật ảnh đại diện để tải về, nếu đã cập nhật vui lòng bỏ qua!`
      );
    }
  }, [user]);

  const chakras = Object.keys(matrixData?.thirdReport).filter(
    (key) =>
      ![
        "id",
        "matrix_id",
        "total",
        "created_at",
        "updated_at",
        "deleted_at",
      ].includes(key)
  );

  const chunkedData = [
    matrixData?.fourthReport?.slice(0, 17), // 17 phần tử đầu tiên
    matrixData?.fourthReport?.slice(17, 34), // 17 phần tử tiếp theo
    matrixData?.fourthReport?.slice(34, 51), // 17 phần tử tiếp theo
    matrixData?.fourthReport?.slice(51), // Phần còn lại có 14 phần tử
  ];

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{ "& .MuiDialog-paper": { width: "1200px", maxWidth: "none" } }}
    >
      <DialogContent className="mx-auto">
        <div className="w-[1123px] " id={`pdf_content_${matrixData.id}`}>
          {/* PDF PAGE 1 */}
          <div
            id={`pdf_page_1_${matrixData.id}`}
            className="h-[792px]  text-[#000]"
            style={{ backgroundImage: `url(${Images.bg_matrix})` }}
          >
            {/* Header */}
            <div
              className="flex w-full flex-col items-center justify-center bg-cover bg-no-repeat pl-4 pr-6"
              // style={{ backgroundImage: `url(${Images.header})` }}
            >
              <div className="flex w-full  items-center justify-between ">
                {/* Logo */}
                <img
                  src={Images.ketnoi}
                  className="avatar w-[120px]  md:w-[130px]"
                  alt="Logo"
                />

                <div>
                  <h4 className="text-md pt-2 text-center font-semibold text-[#000]">
                    {capitalizeName(
                      matrixData?.matrixes?.fullName || matrixData?.fullName
                    )}
                  </h4>
                  <h4 className="text-md text-center font-semibold text-[#000]">
                    {matrixData?.birthday}
                  </h4>
                </div>

                <div className="my-2 flex items-center gap-4">
                  {/* <img
                    src={user?.avatar || Images.avatar}
                    className="h-[55px] w-[55px] rounded-full"
                    alt="Avatar"
                  /> */}

                  {matrixData?.creator?.avatar ? (
                    <img
                      // src={user_info.user.avatar}
                      src={`${HOST}${FILE}${PROXY_IMAGE}?url=${encodeURIComponent(
                        matrixData?.creator?.avatar
                      )}`}
                      className="  h-[55px] w-[55px] rounded-full "
                      alt="Avatar"
                    />
                  ) : (
                    <img
                      // src={userInfo.user.avatar}
                      src={Images.avatar}
                      className="  h-[55px] w-[55px] rounded-full "
                      alt="Avatar"
                    />
                  )}
                  <div>
                    <p className="lg:text-md text-xs font-bold capitalize text-cyan-900  md:text-sm xl:text-lg 2xl:text-xl">
                      {capitalizeName(matrixData?.creator?.name)}
                    </p>
                    <p className="text-xs font-bold text-purple-600 md:text-sm">
                      Hotline: {matrixData?.creator?.phone_number || "---"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Body */}
            <div className="px-6">
              <div className="mt-1 grid grid-cols-[6fr_6fr] gap-4">
                {/* Matrix chart */}
                <div>
                  <h4 className="text-sm font-semibold">Ma Trận Vận Mệnh</h4>
                  <div
                    className="relative ml-[12px] min-h-[515px] min-w-[515px] bg-cover bg-center bg-no-repeat p-4"
                    style={{
                      backgroundImage: `url(${Images.matrix_chart_svg})`,
                    }}
                  >
                    <div className="min-h-[515px] min-w-[515px]">
                      {/* Tuổi */}
                      <div className="absolute left-[-12px] top-[255px] w-[30px] text-center text-[15px] font-semibold text-[#000]">
                        0 tuổi
                      </div>
                      <div className="absolute  left-[52px] top-[72px] w-[30px] text-center text-[15px] font-semibold text-[#000]">
                        10 tuổi
                      </div>
                      <div className="absolute left-[210px] top-[0px] w-[130px]  text-center text-[15px] font-semibold text-[#000]">
                        20 tuổi
                      </div>
                      <div className="absolute  right-[52px] top-[72px] w-[30px] text-center text-[15px] font-semibold text-[#000]">
                        30 tuổi
                      </div>
                      <div className="absolute right-[-12px] top-[255px] w-[30px] text-center text-[15px] font-semibold text-[#000]">
                        40 tuổi
                      </div>
                      <div className="absolute  bottom-[68px] right-[52px] w-[30px] text-center text-[15px] font-semibold text-[#000]">
                        50 tuổi
                      </div>
                      <div className="absolute bottom-[-4px] left-[210px] w-[130px] text-center text-[15px] font-semibold text-[#000]">
                        60 tuổi
                      </div>
                      <div className="absolute  bottom-[68px] left-[52px] w-[30px] text-center text-[15px] font-semibold text-[#000]">
                        70 tuổi
                      </div>
                      {/* A-B-C-D */}
                      <div className="absolute left-[30px] top-[260px] w-[30px] text-center text-[20px] font-semibold text-white">
                        {matrixData?.matrixes?.A}
                      </div>
                      <div className="absolute left-[260px] top-[34px] w-[30px]  text-center text-[20px] font-semibold text-white">
                        {matrixData?.matrixes?.B}
                      </div>
                      <div className="absolute right-[30px] top-[260px] w-[30px] text-center text-[20px] font-semibold text-white">
                        {matrixData?.matrixes?.C}
                      </div>
                      <div className="absolute bottom-[30px] left-[260px] w-[30px] text-center text-[20px] font-semibold text-white">
                        {matrixData?.matrixes?.D}
                      </div>
                      {/* E */}
                      <div className="absolute left-[259px]  top-[260px] w-[30px] text-center text-[20px] font-semibold text-[#000]">
                        {matrixData?.matrixes?.E}
                      </div>
                      {/* F-G-H-I */}
                      <div className="absolute left-[98px]  top-[102px] w-[30px] text-center text-[20px] font-semibold text-[#000]">
                        {matrixData?.matrixes?.F}
                      </div>
                      <div className="absolute right-[98px]  top-[102px] w-[30px] text-center text-[20px] font-semibold text-[#000]">
                        {matrixData?.matrixes?.G}
                      </div>
                      <div className="absolute bottom-[94px]  right-[96px] w-[30px] text-center text-[20px] font-semibold text-[#000]">
                        {matrixData?.matrixes?.H}
                      </div>
                      <div className="absolute bottom-[94px]  left-[96px] w-[30px] text-center text-[20px] font-semibold text-[#000]">
                        {matrixData?.matrixes?.I}
                      </div>
                      {/* K-L-M-N */}
                      <div className="absolute left-[85px] top-[265px] w-[20px] text-center font-semibold text-white">
                        {matrixData?.matrixes?.K}
                      </div>
                      <div className="absolute left-[265px] top-[88px] w-[20px] text-center font-semibold text-white">
                        {matrixData?.matrixes?.L}
                      </div>
                      <div className="absolute  right-[85px] top-[265px] w-[20px] text-center font-semibold">
                        {matrixData?.matrixes?.M}
                      </div>
                      <div className="absolute  bottom-[84px] left-[265px] w-[20px] text-center font-semibold">
                        {matrixData?.matrixes?.N}
                      </div>
                      {/* O-P-Q-R */}
                      <div className="absolute  left-[138px] top-[138px] w-[22px] text-center font-semibold text-[#000]">
                        {matrixData?.matrixes?.O}
                      </div>
                      <div className="absolute  right-[137px] top-[139px] w-[22px] text-center font-semibold text-[#000]">
                        {matrixData?.matrixes?.P}
                      </div>
                      <div className="absolute  bottom-[132px] right-[137px] w-[22px] text-center font-semibold text-[#000]">
                        {matrixData?.matrixes?.Q}
                      </div>
                      <div className="absolute  bottom-[132px] left-[137px] w-[22px] text-center font-semibold text-[#000]">
                        {matrixData?.matrixes?.R}
                      </div>
                      {/* S-T-U-V */}
                      <div className="absolute  left-[120px] top-[266px] w-[15px] text-center text-[12px] font-semibold text-white">
                        {matrixData?.matrixes?.S}
                      </div>
                      <div className="absolute  left-[266px] top-[121px] w-[15px] text-center text-[12px] font-semibold text-white">
                        {matrixData?.matrixes?.T}
                      </div>
                      <div className="absolute  right-[119px] top-[267px] w-[15px] text-center text-[12px] font-semibold text-white ">
                        {matrixData?.matrixes?.U}
                      </div>
                      <div className="absolute  bottom-[117px] left-[266px] w-[15px] text-center text-[12px] font-semibold  text-white">
                        {matrixData?.matrixes?.V}
                      </div>
                      {/* A1-A2-A3-A4 */}
                      <div className="absolute  left-[161px] top-[164px] w-[15px] text-center text-[12px] font-semibold text-[#000]">
                        {matrixData?.matrixes?.A1}
                      </div>
                      <div className="absolute  right-[163px] top-[165px] w-[15px] text-center text-[12px] font-semibold text-[#000]">
                        {matrixData?.matrixes?.A2}
                      </div>
                      <div className="absolute  bottom-[159px] right-[161px] w-[15px] text-center text-[12px] font-semibold text-[#000]">
                        {matrixData?.matrixes?.A3}
                      </div>
                      <div className="absolute  bottom-[160px] left-[162px] w-[15px] text-center text-[12px] font-semibold text-[#000]">
                        {matrixData?.matrixes?.A4}
                      </div>
                      {/* B1-B2-B3 */}
                      <div className="absolute  bottom-[189px] right-[191px] w-[15px] text-center text-[12px] font-semibold text-[#000] ">
                        {matrixData?.matrixes?.B1}
                      </div>
                      <div className="absolute  bottom-[158px] right-[222px] w-[15px] text-center text-[12px] font-semibold text-[#000] ">
                        {matrixData?.matrixes?.B2}
                      </div>
                      <div className="absolute  bottom-[218px] right-[161px] w-[15px] text-center text-[12px] font-semibold text-[#000] ">
                        {matrixData?.matrixes?.B3}
                      </div>
                      {/* C1-C2 */}
                      <div className="absolute  left-[187px] top-[267px] w-[15px] text-center text-[12px] font-semibold text-white">
                        {matrixData?.matrixes?.C1}
                      </div>
                      <div className="absolute  left-[266px] top-[188px] w-[15px] text-center text-[12px] font-semibold text-white">
                        {matrixData?.matrixes?.C2}
                      </div>
                      {/* D1-D2 */}
                      <div className="absolute  left-[311px] top-[264px] w-[25px] text-center font-semibold text-[#000]">
                        {matrixData?.matrixes?.D1}
                      </div>
                      <div className="absolute  left-[347px] top-[267px] w-[15px] text-center text-[12px] font-semibold text-[#000]">
                        {matrixData?.matrixes?.D2}
                      </div>
                      {/* fourthReport */}
                      {/* 1 - 9 */}
                      <div className="absolute left-[43px] top-[234px]  text-center text-[6px] font-semibold text-[#000]">
                        1 - 2,5
                      </div>
                      <div className="absolute left-[20px] top-[230px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[1]?.data[0]}
                      </div>
                      <div className="absolute left-[48px] top-[219px]  text-center text-[6px] font-semibold text-[#000]">
                        2,5 - 3,5
                      </div>
                      <div className="absolute left-[26px] top-[215px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[2]?.data[0]}
                      </div>
                      <div className="absolute left-[55px] top-[204px]  text-center text-[6px] font-semibold text-[#000]">
                        3,5 - 4
                      </div>
                      <div className="absolute left-[32px] top-[200px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[3]?.data[0]}
                      </div>
                      <div className="absolute left-[64px] top-[185px]  text-center text-[10px] font-bold text-[#000]">
                        5 tuổi
                      </div>
                      <div className="absolute left-[38px] top-[181px] w-[15px]  text-center text-[12px] font-bold text-[#000]">
                        {matrixData?.fourthReport[4]?.data[0]}
                      </div>
                      <div className="absolute left-[67px] top-[170px]  text-center text-[6px] font-semibold text-[#000]">
                        6 - 7,5
                      </div>
                      <div className="absolute left-[48px] top-[164px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[5]?.data[0]}
                      </div>
                      <div className="absolute left-[75px] top-[154px]  text-center text-[6px] font-semibold text-[#000]">
                        7,5 - 8,5
                      </div>
                      <div className="absolute left-[56px] top-[148px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[6]?.data[0]}
                      </div>
                      <div className="absolute left-[81px] top-[138px]  text-center text-[6px] font-semibold text-[#000]">
                        8,5 - 9
                      </div>
                      <div className="absolute left-[62px] top-[130px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[7]?.data[0]}
                      </div>
                      {/* 9 - 18 */}
                      <div className="absolute left-[128px] top-[88px]  text-center text-[6px] font-semibold text-[#000]">
                        11 - 12,5
                      </div>
                      <div className="absolute left-[126px] top-[64px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[9]?.data[0]}
                      </div>
                      <div className="absolute left-[148px] top-[80px]  text-center text-[6px] font-semibold text-[#000]">
                        12,5 - 13,5
                      </div>
                      <div className="absolute left-[146px] top-[56px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[10]?.data[0]}
                      </div>
                      <div className="absolute left-[168px] top-[72px]  text-center text-[6px] font-semibold text-[#000]">
                        13,5 - 14
                      </div>
                      <div className="absolute left-[162px] top-[50px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[11]?.data[0]}
                      </div>
                      <div className="absolute left-[186px] top-[62px]  text-center text-[10px] font-semibold text-[#000]">
                        15 tuổi
                      </div>
                      <div className="absolute left-[180px] top-[36px] w-[15px]  text-center text-[12px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[12]?.data[0]}
                      </div>
                      <div className="absolute left-[202px] top-[56px]  text-center text-[6px] font-semibold text-[#000]">
                        16 - 17,5
                      </div>
                      <div className="absolute left-[200px] top-[34px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[13]?.data[0]}
                      </div>
                      <div className="absolute left-[218px] top-[48px]  text-center text-[6px] font-semibold text-[#000]">
                        17,5-18,5
                      </div>
                      <div className="absolute left-[214px] top-[28px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[14]?.data[0]}
                      </div>
                      <div className="absolute left-[228px] top-[41px]  text-center text-[6px] font-semibold text-[#000]">
                        18,5-19
                      </div>
                      <div className="absolute left-[230px] top-[21px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[15]?.data[0]}
                      </div>
                      {/* 21 - 29 */}
                      <div className="absolute right-[226px] top-[41px]  text-center text-[6px] font-semibold text-[#000]">
                        21-22,5
                      </div>
                      <div className="absolute right-[226px] top-[23px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[17]?.data[0]}
                      </div>
                      <div className="absolute right-[213px] top-[48px]  text-center text-[6px] font-semibold text-[#000]">
                        22,5-23,5
                      </div>
                      <div className="absolute right-[214px] top-[28px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[18]?.data[0]}
                      </div>
                      <div className="absolute right-[202px] top-[56px]  text-center text-[6px] font-semibold text-[#000]">
                        23,5-24
                      </div>
                      <div className="absolute right-[200px] top-[33px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[19]?.data[0]}
                      </div>
                      <div className="absolute right-[186px] top-[62px]  text-center text-[10px] font-semibold text-[#000]">
                        25 tuổi
                      </div>
                      <div className="absolute right-[180px] top-[36px] w-[15px]  text-center text-[12px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[20]?.data[0]}
                      </div>
                      <div className="absolute right-[168px] top-[72px]  text-center text-[6px] font-semibold text-[#000]">
                        26 - 27,5
                      </div>
                      <div className="absolute right-[162px] top-[50px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[21]?.data[0]}
                      </div>
                      <div className="absolute right-[148px] top-[80px]  text-center text-[6px] font-semibold text-[#000]">
                        27,5 - 28,5
                      </div>
                      <div className="absolute right-[146px] top-[56px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[22]?.data[0]}
                      </div>
                      <div className="absolute right-[130px] top-[88px]  text-center text-[6px] font-semibold text-[#000]">
                        28,5 - 29
                      </div>
                      <div className="absolute right-[126px] top-[64px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[23]?.data[0]}
                      </div>
                      {/* 31 - 39 */}
                      <div className="absolute right-[81px] top-[140px]  text-center text-[6px] font-semibold text-[#000]">
                        31-32,5
                      </div>
                      <div className="absolute right-[62px] top-[132px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[25]?.data[0]}
                      </div>{" "}
                      <div className="absolute right-[75px] top-[154px]  text-center text-[6px] font-semibold text-[#000]">
                        32,5 - 33,5
                      </div>
                      <div className="absolute right-[56px] top-[150px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[26]?.data[0]}
                      </div>
                      <div className="absolute right-[67px] top-[170px]  text-center text-[6px] font-semibold text-[#000]">
                        33,5 - 34
                      </div>
                      <div className="absolute right-[48px] top-[166px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[27]?.data[0]}
                      </div>
                      <div className="absolute right-[64px] top-[185px]  text-center text-[10px] font-bold text-[#000]">
                        35 tuổi
                      </div>
                      <div className="absolute right-[38px] top-[183px] w-[15px]  text-center text-[12px] font-bold text-[#000]">
                        {matrixData?.fourthReport[28]?.data[0]}
                      </div>
                      <div className="absolute right-[55px] top-[204px]  text-center text-[6px] font-semibold text-[#000]">
                        36 - 37,5
                      </div>
                      <div className="absolute right-[32px] top-[202px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[29]?.data[0]}
                      </div>
                      <div className="absolute right-[48px] top-[219px]  text-center text-[6px] font-semibold text-[#000]">
                        37,5 - 38,5
                      </div>
                      <div className="absolute right-[26px] top-[217px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[30]?.data[0]}
                      </div>
                      <div className="absolute right-[43px] top-[234px]  text-center text-[6px] font-semibold text-[#000]">
                        38,5 - 39
                      </div>
                      <div className="absolute right-[20px] top-[232px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[31]?.data[0]}
                      </div>
                      {/* 41 - 49 */}
                      <div className="absolute bottom-[230px] right-[43px]  text-center text-[6px] font-semibold text-[#000]">
                        41-42,5
                      </div>
                      <div className="absolute bottom-[222px] right-[20px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[33]?.data[0]}
                      </div>
                      <div className="absolute bottom-[215px] right-[48px]  text-center text-[6px] font-semibold text-[#000]">
                        42,5 - 4,5
                      </div>
                      <div className="absolute bottom-[207px] right-[26px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[34]?.data[0]}
                      </div>
                      <div className="absolute bottom-[200px] right-[55px]  text-center text-[6px] font-semibold text-[#000]">
                        43,5 - 44
                      </div>
                      <div className="absolute bottom-[192px] right-[32px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[35]?.data[0]}
                      </div>
                      <div className="absolute bottom-[181px] right-[64px]  text-center text-[10px] font-bold text-[#000]">
                        45 tuổi
                      </div>
                      <div className="absolute bottom-[173px] right-[38px] w-[15px]  text-center text-[12px] font-bold text-[#000]">
                        {matrixData?.fourthReport[36]?.data[0]}
                      </div>
                      <div className="absolute bottom-[166px] right-[67px]  text-center text-[6px] font-semibold text-[#000]">
                        46 - 47,5
                      </div>
                      <div className="absolute bottom-[156px] right-[48px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[37]?.data[0]}
                      </div>
                      <div className="absolute bottom-[150px] right-[75px]  text-center text-[6px] font-semibold text-[#000]">
                        47,5 - 48,5
                      </div>
                      <div className="absolute bottom-[136px] right-[56px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[38]?.data[0]}
                      </div>
                      <div className="absolute bottom-[136px] right-[81px]  text-center text-[6px] font-semibold text-[#000]">
                        48,5 - 49
                      </div>
                      <div className="absolute bottom-[122px] right-[62px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[39]?.data[0]}
                      </div>
                      {/* 51 - 59 */}
                      <div className="absolute bottom-[80px] right-[130px]  text-center text-[6px] font-semibold text-[#000]">
                        51-52,5
                      </div>
                      <div className="absolute bottom-[57px] right-[132px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[41]?.data[0]}
                      </div>
                      <div className="absolute bottom-[72px] right-[142px]  text-center text-[6px] font-semibold text-[#000]">
                        52,5-53,5
                      </div>
                      <div className="absolute bottom-[50px] right-[148px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[42]?.data[0]}
                      </div>
                      <div className="absolute bottom-[64px] right-[162px]  text-center text-[6px] font-semibold text-[#000]">
                        53,5-54
                      </div>
                      <div className="absolute bottom-[44px] right-[164px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[43]?.data[0]}
                      </div>
                      <div className="absolute bottom-[56px] right-[186px]  text-center text-[10px] font-semibold text-[#000]">
                        55 tuổi
                      </div>
                      <div className="absolute bottom-[31px] right-[180px] w-[15px]  text-center text-[12px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[44]?.data[0]}
                      </div>
                      <div className="absolute bottom-[50px] right-[202px]  text-center text-[6px] font-semibold text-[#000]">
                        56 -57,5
                      </div>
                      <div className="absolute bottom-[28px] right-[200px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[45]?.data[0]}
                      </div>
                      <div className="absolute bottom-[43px] right-[211px]  text-center text-[6px] font-semibold text-[#000]">
                        57,5 -58,5
                      </div>
                      <div className="absolute bottom-[22px] right-[214px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[46]?.data[0]}
                      </div>
                      <div className="absolute bottom-[36px] right-[223px]  text-center text-[6px] font-semibold text-[#000]">
                        58,5 -59
                      </div>
                      <div className="absolute bottom-[16px] right-[228px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[47]?.data[0]}
                      </div>
                      {/* 61 - 69 */}
                      <div className="absolute bottom-[35px] left-[228px]  text-center text-[6px] font-semibold text-[#000]">
                        61-62,5
                      </div>
                      <div className="absolute bottom-[16px] left-[228px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[49]?.data[0]}
                      </div>
                      <div className="absolute bottom-[43px] left-[214px]  text-center text-[6px] font-semibold text-[#000]">
                        62,5-63,5
                      </div>
                      <div className="absolute bottom-[22px] left-[214px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[50]?.data[0]}
                      </div>
                      <div className="absolute bottom-[50px] left-[202px]  text-center text-[6px] font-semibold text-[#000]">
                        63,5-64
                      </div>
                      <div className="absolute bottom-[26px] left-[200px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[51]?.data[0]}
                      </div>
                      <div className="absolute bottom-[56px] left-[186px]  text-center text-[10px] font-semibold text-[#000]">
                        65 tuổi
                      </div>
                      <div className="absolute bottom-[31px] left-[180px] w-[15px]  text-center text-[12px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[52]?.data[0]}
                      </div>
                      <div className="absolute bottom-[64px] left-[162px]  text-center text-[6px] font-semibold text-[#000]">
                        66 -67,5
                      </div>
                      <div className="absolute bottom-[44px] left-[164px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[53]?.data[0]}
                      </div>
                      <div className="absolute bottom-[72px] left-[142px]  text-center text-[6px] font-semibold text-[#000]">
                        67,5 -68,5
                      </div>
                      <div className="absolute bottom-[50px] left-[148px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[54]?.data[0]}
                      </div>
                      <div className="absolute bottom-[80px] left-[130px]  text-center text-[6px] font-semibold text-[#000]">
                        68,5 -69
                      </div>
                      <div className="absolute bottom-[57px] left-[132px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[55]?.data[0]}
                      </div>
                      {/* 71 - 79 */}
                      <div className="absolute bottom-[136px] left-[81px]  text-center text-[6px] font-semibold text-[#000]">
                        71-72,5
                      </div>
                      <div className="absolute bottom-[122px] left-[62px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[57]?.data[0]}
                      </div>
                      <div className="absolute bottom-[150px] left-[75px]  text-center text-[6px] font-semibold text-[#000]">
                        72,5 - 7,5
                      </div>
                      <div className="absolute bottom-[140px] left-[56px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[58]?.data[0]}
                      </div>
                      <div className="absolute bottom-[166px] left-[67px]  text-center text-[6px] font-semibold text-[#000]">
                        73,5 - 74
                      </div>
                      <div className="absolute bottom-[156px] left-[48px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[59]?.data[0]}
                      </div>
                      <div className="absolute bottom-[181px] left-[64px]  text-center text-[10px] font-bold text-[#000]">
                        75 tuổi
                      </div>
                      <div className="absolute bottom-[173px] left-[38px] w-[15px]  text-center text-[12px] font-bold text-[#000]">
                        {matrixData?.fourthReport[60]?.data[0]}
                      </div>
                      <div className="absolute bottom-[200px] left-[55px]  text-center text-[6px] font-semibold text-[#000]">
                        76 - 77,5
                      </div>
                      <div className="absolute bottom-[192px] left-[32px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[61]?.data[0]}
                      </div>
                      <div className="absolute bottom-[215px] left-[48px]  text-center text-[6px] font-semibold text-[#000]">
                        77,5 - 78,5
                      </div>
                      <div className="absolute bottom-[207px] left-[26px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[62]?.data[0]}
                      </div>
                      <div className="absolute bottom-[230px] left-[43px]  text-center text-[6px] font-semibold text-[#000]">
                        78,5 - 79
                      </div>
                      <div className="absolute bottom-[222px] left-[20px] w-[15px]  text-center text-[10px] font-semibold text-[#000]">
                        {matrixData?.fourthReport[63]?.data[0]}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Table */}
                <div className="pl-6">
                  <h4 className="mb-2 text-sm font-semibold">
                    Sức Khỏe Vật Lý & Tinh Thần
                  </h4>
                  {/* <div className="mb-8s mb-6 flex h-full w-full items-center justify-center"> */}
                  <ChakraTable
                    chakras={chakras}
                    data={matrixData?.thirdReport}
                  />
                  {/* </div> */}
                </div>
              </div>
              <div className="mt-1 flex justify-around">
                <div className="w-[200px]">
                  <div>
                    <h5 className=" text-sm font-semibold text-[#000]">
                      Giai đoạn 1: Dành cho chính mình
                    </h5>
                    <h6 className=" text-justify text-xs text-[#000]">
                      Nhiệm vụ cần hoàn thành trước năm 40 tuổi
                    </h6>
                  </div>
                </div>
                <div className="w-[200px]">
                  <div>
                    <h5 className=" text-sm font-semibold text-[#000]">
                      Giai đoạn 2: Dành cho xã hội
                    </h5>
                    <h6 className=" text-justify text-xs text-[#000]">
                      Nhiệm vụ cần hoàn thành trước năm 60 tuổi. Giải quyết các
                      vấn đề liên quan giữa cá nhân và người khác
                    </h6>
                  </div>
                </div>
                <div className="w-[200px]">
                  <div>
                    <h5 className=" text-sm font-semibold text-[#000]">
                      Giai đoạn 3: Dành cho tâm linh
                    </h5>
                    <h6 className=" text-justify text-xs text-[#000]">
                      Bài kiểm tra tâm linh. Tôi là ai trước mặt Đấng Sáng Tạo?
                      Tâm linh trong tôi ẩn chứa điều gì?
                    </h6>
                  </div>
                </div>
              </div>
              <div className="mt-1 flex justify-around">
                <div className="w-[200px]">
                  <div className="relative flex items-center justify-center gap-2">
                    <div>
                      <div className="mb-2 flex h-7 w-7 items-center justify-center rounded-full border border-gray-600 bg-white font-semibold">
                        {matrixData?.secondReport?.stage_1?.skyline}
                      </div>
                      <div className="flex h-7 w-7 items-center justify-center rounded-full border border-gray-600 bg-white font-semibold">
                        {matrixData?.secondReport?.stage_1?.earthline}
                      </div>
                    </div>
                    <div className="text-[50px] font-thin">]-</div>
                    <div className="flex h-7 w-7 items-center justify-center rounded-full border border-gray-600 bg-[#eab308] font-semibold">
                      {matrixData?.secondReport?.stage_1?.total}
                    </div>
                  </div>
                </div>
                <div className="w-[200px]">
                  <div className="relative flex items-center justify-center gap-2">
                    <div>
                      <div className="mb-2 flex h-7 w-7 items-center justify-center rounded-full border border-gray-600 bg-white font-semibold">
                        {matrixData?.secondReport?.stage_2?.father}
                      </div>
                      <div className="flex h-7 w-7 items-center justify-center rounded-full border border-gray-600 bg-white font-semibold">
                        {matrixData?.secondReport?.stage_2?.mother}
                      </div>
                    </div>
                    <div className="text-[50px] font-thin">]-</div>
                    <div className="flex h-7 w-7 items-center justify-center rounded-full border border-gray-600 bg-[#6ead32] font-semibold">
                      {matrixData?.secondReport?.stage_2?.total}
                    </div>
                  </div>
                </div>
                <div className="w-[200px]">
                  <div className="relative flex items-center justify-center gap-2">
                    <div>
                      <div className="mb-2 flex h-7 w-7 items-center justify-center rounded-full border border-gray-600 bg-[#eab308] font-semibold">
                        {matrixData?.secondReport?.stage_3?.number1}
                      </div>
                      <div className="flex h-7 w-7 items-center justify-center rounded-full border border-gray-600 bg-[#6ead32] font-semibold">
                        {matrixData?.secondReport?.stage_3?.number2}
                      </div>
                    </div>
                    <div className="text-[50px] font-thin">]-</div>
                    <div className="flex h-7 w-7 items-center justify-center rounded-full border border-gray-600 bg-[#3bb8f6] font-semibold">
                      {matrixData?.secondReport?.stage_3?.total}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* PDF PAGE 2 */}
          <div
            className="mt-2 h-[792px] text-[#000]"
            id={`pdf_page_2_${data?.data.id}`}
            style={{ backgroundImage: `url(${Images.bg_matrix})` }}
          >
            {/* <h4 className="text-md pt-2 text-center font-semibold text-[#000]">
              {capitalizeName(
                matrixData?.matrixes?.fullName || matrixData?.fullName
              )}
            </h4>
            <h4 className="text-md text-center font-semibold text-[#000]">
              {matrixData?.birthday}
            </h4> */}
            <div className=" px-6">
              <h4 className="mb-2 pt-2 text-sm font-semibold">
                Năng Lượng Bảo Hộ Qua Mỗi Năm
              </h4>
              <h4 className="mb-3 text-sm font-semibold">
                Từ {getYearOfBirth(matrixData?.birthday)} Đến{" "}
                {getYearOfBirth(matrixData?.birthday) + 80}
              </h4>
              <div className="grid grid-cols-4 gap-3">
                {chunkedData.map((chunk, index) => (
                  <div key={index} className="rounded-lg">
                    <table className="w-full table-auto border-collapse border border-gray-800">
                      <thead>
                        <tr className="bg-[#f3f6fc]">
                          <th className="border-l border-r border-t border-gray-800 px-4 py-2 text-center text-[16px] font-semibold">
                            Tuổi
                          </th>
                          <th className="border-r border-t border-gray-800 px-4 py-2 text-center text-[16px] font-semibold">
                            Năng lượng
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {chunk.map((item, index) => (
                          <tr
                            key={item.id}
                            className={
                              index % 2 === 0 ? "bg-white" : "bg-[#f3f6fc]"
                            } // Màu nền xen kẽ
                          >
                            <td className="border-l border-r border-gray-800 px-4 py-2 font-semibold">
                              {item.age}
                            </td>
                            <td className="border-r border-gray-800 px-4 py-2 font-semibold">
                              <div className="flex items-center justify-around">
                                {item?.data?.map((itemData, index) => (
                                  <div className="flex w-[20px] justify-center">
                                    {itemData}
                                  </div>
                                ))}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            </div>
            {/* Footer */}
            <footer
              className="mt-2 flex h-[96px] w-full items-center justify-center bg-cover bg-no-repeat "
              // style={{ backgroundImage: `url(${Images.footer}` }}
            >
              <h2
                className="text-5xl font-bold  text-[#000]"
                style={{ fontFamily: "Dancing Script" }}
              >
                Thank You
              </h2>
            </footer>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="outlined"
          className="border-solid border-primaryPurple text-primaryPurple"
        >
          Đóng
        </Button>
        <Button
          onClick={() => handleDownloadPdf(matrixData.id)}
          className="bg-primaryPurple text-white"
        >
          Tải xuống
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogReport;
