import moment from "moment";
import { getItemFromCookieStorage } from "../cookie";
import { AUTHENTICATION } from "constant/AuthConstant";
import { uploadSingleFile } from "services/file.service";

export const formattedDate = (dateStr) =>
  moment(dateStr).format("DD / MM / YYYY | HH:mm:ss");

export const formattedDateSoulPlan = (dateStr) =>
  moment(dateStr).format("DD/MM/YYYY");

export function repeatKeys(obj, key) {
  const keyStr = key.toString();

  if (obj.hasOwnProperty(keyStr)) {
    return keyStr.repeat(obj[keyStr]);
  } else {
    return "---";
  }
}

export const checkLogin = () => {
  const isAuthenticated = !!getItemFromCookieStorage(AUTHENTICATION);

  return isAuthenticated;
};

export const getIcon = (number) => {
  return `${number.png}`;
};

export const modifiedName = (name) => name.replace(/ /g, "_");

export const uploadFileToStorage = async (file, fileName, folderName) => {
  try {
    const fileUrl = await uploadSingleFile(true, file, fileName, folderName);

    return fileUrl;
  } catch (error) {
    return null;
  }
};

export const capitalizeName = (name) => {
  if (name) {
    const trimmedName = name.trim();

    const capitalizedName = trimmedName
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return capitalizedName;
  } else {
    return "";
  }
};

export const getYearOfBirth = (dateString) => {
  const dateParts = dateString.split("/");
  return parseInt(dateParts[2], 10);
};
