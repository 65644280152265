import LogoSoul from "../assets/images/logo.png";
import iconSixPointedStar from "../assets/images/six-pointed-star.svg";
import roadmap from "../assets/images/road-map.jpg";
import matrix from "../assets/images/matrix.svg";
import chart from "../assets/images/chart.svg";
import group from "../assets/images/group.svg";
import vatchatythuc from "../assets/images/vatchatythuc.png";
import nguhanh from "../assets/images/nguhanh.png";
import saosaucanh from "../assets/images/saosaucanh.png";
import tamgiac from "../assets/images/tamgiac.png";
import tickBlue from "../assets/images/tickBlue.svg";
import tickPurple from "../assets/images/tickPurple.svg";
import log from "../assets/images/log.svg";
import header from "../assets/images/header.png";
import footer from "../assets/images/footer.png";
import body from "../assets/images/body.png";
import ketnoi from "../assets/images/ketnoi.png";
import avatar from "../assets/images/avatar.png";
import avatar9 from "../assets/img/avatars/avatar9.png";

import bg_matrix from "../assets/images/bg_matrix.jpg";
import matrix_chart from "../assets/images/matrix_chart.png";
import matrix_chart_svg from "../assets/images/matrix_chart.svg";

import icon1 from "../assets/icons/1.png";
import icon2 from "../assets/icons/2.png";
import icon3 from "../assets/icons/3.png";
import icon4 from "../assets/icons/4.png";
import icon5 from "../assets/icons/5.png";
import icon6 from "../assets/icons/6.png";
import icon7 from "../assets/icons/7.png";
import icon8 from "../assets/icons/8.png";
import icon9 from "../assets/icons/9.png";
import icon10 from "../assets/icons/10.png";
import icon11 from "../assets/icons/11.png";
import icon12 from "../assets/icons/12.png";
import icon13 from "../assets/icons/13.png";
import icon14 from "../assets/icons/14.png";
import icon15 from "../assets/icons/15.png";
import icon16 from "../assets/icons/16.png";
import icon17 from "../assets/icons/17.png";
import icon18 from "../assets/icons/18.png";
import icon19 from "../assets/icons/19.png";
import icon20 from "../assets/icons/20.png";
import icon21 from "../assets/icons/21.png";
import icon22 from "../assets/icons/22.png";

const Images = {
  LogoSoul,
  header,
  footer,
  body,
  ketnoi,
  log,
  iconSixPointedStar,
  roadmap,
  matrix,
  chart,
  group,
  vatchatythuc,
  nguhanh,
  saosaucanh,
  tamgiac,
  avatar9,
  tickBlue,
  tickPurple,
  icon1,
  icon2,
  icon3,
  icon4,
  icon5,
  icon6,
  icon7,
  icon8,
  icon9,
  icon10,
  icon11,
  icon12,
  icon13,
  icon14,
  icon15,
  icon16,
  icon17,
  icon18,
  icon19,
  icon20,
  icon21,
  icon22,
  bg_matrix,
  matrix_chart,
  avatar,
  matrix_chart_svg,
};

export default Images;
