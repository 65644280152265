import React, { useEffect, useState } from "react";
import Images from "constant/Images";
import { repeatKeys } from "utils/helpers";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import ImageCheck from "./ImageCheck";
import SoulPlanIcons from "components/icons/SoulPlanIcon";
import { useSelector } from "react-redux";
import { capitalizeName } from "utils/helpers";
import { HOST } from "constant/Api";
import { FILE } from "constant/Api";
import { PROXY_IMAGE } from "constant/Api";
import { toast } from "react-toastify";
// import SoulPlanIconTableDoubleCell from "components/icons/SoulPlanIconTableDoubleCell";
// import SoulPlanTickIconTable from "components/icons/SoulPlanTickIconTable";
// import html2canvas from "html2canvas-pro";
// import domtoimage from "dom-to-image";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogReport = ({
  open,
  handleClose,
  user_info,
  data,
  handleDownloadPdf,
}) => {
  const { user } = useSelector((state) => state.user);
  const { fullName, birthday, gender, awareness, lifeStage, soulPlan } =
    data.data;

  const matrixData = data.data;

  const [userInfo, setUserInfo] = useState(null);

  // Load user info from local storage or some other source asynchronously
  useEffect(() => {
    if (user && user.avatar) {
      setUserInfo(user); // Set the user info once available
    } else {
      toast.warning(
        `Bạn phải cập nhật ảnh đại diện để tải về, nếu đã cập nhật vui lòng bỏ qua!`
      );
    }
  }, [user]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{ "& .MuiDialog-paper": { width: "900px", maxWidth: "none" } }}
    >
      {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
      <DialogContent className="mx-auto">
        {/* Pdf */}

        <div
          className="h-[1123px] w-[792px]"
          id={`pdf_content_${data?.data.id}`}
          // id={"capture"}
        >
          {/* Header */}
          <div
            className="flex w-full flex-col items-center justify-center bg-cover bg-no-repeat pb-6 pt-4"
            style={{ backgroundImage: `url(${Images.header})` }}
          >
            <div className="flex w-full max-w-screen-lg items-center justify-between px-4">
              {/* Logo */}
              <img
                src={Images.ketnoi}
                className="w-[120px]  md:w-[200px]"
                alt="Logo"
              />

              <div className="mx-5 my-2 flex items-center gap-4">
                {/* <img
                  src={user?.avatar || Images.avatar}
                  className="h-[60px] w-[60px] rounded-full"
                  alt="Avatar"
                /> */}
                {matrixData?.creator?.avatar ? (
                  <img
                    // src={userInfo.user.avatar}
                    src={`${HOST}${FILE}${PROXY_IMAGE}?url=${encodeURIComponent(
                      matrixData?.creator?.avatar
                    )}`}
                    className="h-[60px] w-[60px] rounded-full "
                    alt="Avatar"
                  />
                ) : (
                  <img
                    // src={userInfo.user.avatar}
                    src={Images.avatar}
                    className="h-[60px] w-[60px] rounded-full "
                    alt="Avatar"
                  />
                )}
                <div>
                  <p className="lg:text-md text-xs font-bold capitalize text-cyan-900  md:text-sm xl:text-lg 2xl:text-xl">
                    {capitalizeName(matrixData?.creator?.name)}
                  </p>
                  <p className="text-xs font-bold text-purple-600 md:text-sm">
                    Hotline: {matrixData?.creator?.phone_number || "---"}
                  </p>
                </div>
              </div>
            </div>
            <div className="text-center">
              <h2 className="font-cursive text-xl font-bold capitalize text-[#436389]">
                {capitalizeName(fullName)}
              </h2>
              <h2 className="font-cursive font-bold text-[#436389]">
                {birthday} - {gender === "male" ? "Nam" : "Nữ"}
              </h2>
            </div>
          </div>

          {/* {{-- body --}} */}
          <div
            style={{
              // width: "95%",
              // margin: "50px auto",
              backgroundImage: `url(${Images.body}`,
            }}
            id="pdf-content"
            className="px-6 py-10"
          >
            <div style={{ padding: "0 10px" }} id="pdf-content">
              {/* {{-- Awareness Chart  --}} */}
              <div>
                <p className="text-lg font-bold text-[#000]">
                  <span>Spychomatrix - Ma trận nhận thức</span>
                </p>
                <div className="flex items-start justify-between gap-2 pt-6">
                  <div className="">
                    <div>
                      <div className="flex gap-2">
                        <div className="flex h-16 w-16 flex-col items-center justify-center rounded-md bg-purple-800 p-2">
                          <p className="text-center text-xs font-semibold text-white">
                            Hào hứng
                          </p>
                          <p className="text-center text-sm font-bold text-white">
                            {repeatKeys(awareness?.array, 3)}
                          </p>
                        </div>
                        <div className="flex h-16 w-16 flex-col items-center justify-center rounded-md bg-purple-800 p-2">
                          <p className="text-center text-xs font-semibold text-white">
                            Phân tích
                          </p>
                          <p className="text-center text-sm font-bold text-white">
                            {repeatKeys(awareness?.array, 6)}
                          </p>
                        </div>
                        <div className="flex h-16 w-16 flex-col items-center justify-center rounded-md bg-purple-800 p-2">
                          <p className="text-center text-xs font-semibold text-white">
                            Chuyên môn
                          </p>
                          <p className="text-center text-sm font-bold text-white">
                            {repeatKeys(awareness?.array, 9)}
                          </p>
                        </div>
                      </div>
                      <div className="mt-2 flex gap-2">
                        <div className="flex h-16 w-16 flex-col items-center justify-center rounded-md bg-blue-500 p-2">
                          <p className="text-center text-xs font-semibold text-white">
                            Quan sát
                          </p>
                          <p className="text-center text-sm font-bold text-white">
                            {repeatKeys(awareness?.array, 2)}
                          </p>
                        </div>
                        <div className="flex h-16 w-16 flex-col items-center justify-center rounded-md bg-blue-500 p-2">
                          <p className="text-center text-xs font-semibold text-white">
                            Trình bày
                          </p>
                          <p className="text-center text-sm font-bold text-white">
                            {repeatKeys(awareness?.array, 5)}
                          </p>
                        </div>
                        <div className="flex h-16 w-16 flex-col items-center justify-center rounded-md bg-blue-500 p-2">
                          <p className="text-center text-xs font-semibold text-white">
                            Cảm thụ
                          </p>
                          <p className="text-center text-sm font-bold text-white">
                            {repeatKeys(awareness?.array, 8)}
                          </p>
                        </div>
                      </div>
                      <div className="mt-2 flex gap-2">
                        <div className="bg-brown-800 flex h-16 w-16 flex-col items-center justify-center rounded-md bg-orange-900 p-2">
                          <p className="text-center text-xs font-semibold text-white">
                            Ý chí, yếu tố khí
                          </p>
                          <p className="text-center text-sm font-bold text-white">
                            {repeatKeys(awareness?.array, 1)}
                          </p>
                        </div>
                        <div className="bg-brown-800 flex h-16 w-16 flex-col items-center justify-center rounded-md bg-orange-900 p-2">
                          <p className="text-center text-xs font-semibold text-white">
                            Nội lực
                          </p>
                          <p className="text-center text-sm font-bold text-white">
                            {repeatKeys(awareness?.array, 4)}
                          </p>
                        </div>
                        <div className="bg-brown-800 flex h-16 w-16 flex-col items-center justify-center rounded-md bg-orange-900 p-2">
                          <p className="text-center text-xs font-semibold text-white">
                            Tiềm thức
                          </p>
                          <p className="text-center text-sm font-bold text-white">
                            {repeatKeys(awareness?.array, 7)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex h-[212px] w-1/3 flex-col items-center justify-between">
                    <div className=" flex w-[130px] items-center justify-between">
                      <p className="text-md flex items-center font-semibold text-purple-800">
                        <span className="w-4">
                          {awareness?.is_check?.thinking && (
                            <ImageCheck source={Images.tickBlue} width="12px" />
                          )}
                        </span>
                        <span className="ml-1">Tư Duy:</span>
                      </p>
                      <p className="text-md font-semibold text-purple-800">
                        {awareness?.sum_data?.thinking || 0}
                      </p>
                    </div>
                    <div className=" flex w-[130px] items-center justify-between">
                      <p className="text-md flex items-center font-semibold text-purple-800">
                        <span className="w-4">
                          {awareness?.is_check?.feeling && (
                            <ImageCheck source={Images.tickBlue} width="12px" />
                          )}
                        </span>
                        <span className="ml-1">Cảm Xúc:</span>
                      </p>
                      <p className="text-md font-semibold text-purple-800">
                        {awareness?.sum_data?.feeling || 0}
                      </p>
                    </div>
                    <div className=" flex w-[130px] items-center justify-between">
                      <p className="text-md flex items-center font-semibold text-purple-800">
                        <span className="w-4">
                          {awareness?.is_check?.action && (
                            <ImageCheck source={Images.tickBlue} width="12px" />
                          )}
                        </span>
                        <span className="ml-1">Hành Động:</span>
                      </p>
                      <p className="text-md font-semibold text-purple-800">
                        {awareness?.sum_data?.action || 0}
                      </p>
                    </div>
                    <div className=" flex w-[130px] items-center justify-between">
                      <p className="text-md flex items-center font-semibold text-orange-900">
                        <span className="w-4">
                          {awareness?.is_check?.direct && (
                            <ImageCheck
                              source={Images.tickPurple}
                              width="12px"
                            />
                          )}
                        </span>
                        <span className="ml-1">Chỉ Dẫn:</span>
                      </p>
                      <p className="text-md font-semibold text-orange-900">
                        {awareness?.sum_data?.direct || 0}
                      </p>
                    </div>
                    <div className=" flex w-[130px] items-center justify-between">
                      <p className="text-md flex items-center font-semibold text-orange-900">
                        <span className="w-4">
                          {awareness?.is_check?.practice && (
                            <ImageCheck
                              source={Images.tickPurple}
                              width="12px"
                            />
                          )}
                        </span>
                        <span className="ml-1">Thực Hành:</span>
                      </p>
                      <p className="text-md font-semibold text-orange-900">
                        {awareness?.sum_data?.practice || 0}
                      </p>
                    </div>
                    <div className=" flex w-[130px] items-center justify-between">
                      <p className="text-md flex items-center font-semibold text-orange-900">
                        <span className="w-4">
                          {awareness?.is_check?.experience && (
                            <ImageCheck
                              source={Images.tickPurple}
                              width="12px"
                            />
                          )}
                        </span>
                        <span className="ml-1">Trải Nghiệm:</span>
                      </p>
                      <p className="text-md font-semibold text-orange-900">
                        {awareness?.sum_data?.experience || 0}
                      </p>
                    </div>
                  </div>

                  <div className="mt-[-20px] flex flex-col items-center justify-center pt-[-20px] text-center">
                    <img
                      className="h-40 w-40"
                      src={Images.nguhanh}
                      alt="Ngũ Hành"
                    />
                    <div>
                      <div className=" flex items-center gap-1">
                        <div className="flex h-8 w-8 items-center justify-center rounded border border-purple-800">
                          <p className="text-sm font-bold text-purple-800">
                            {awareness?.sum_data?.male_psychology}
                          </p>
                        </div>
                        <p className="text-sm font-bold text-purple-800">
                          Tâm lý tính nam
                        </p>
                      </div>
                      <div className="mt-1 flex items-center gap-1">
                        <div className="flex h-8 w-8 items-center justify-center rounded border border-orange-900">
                          <p className="text-sm font-bold text-orange-900">
                            {awareness?.sum_data?.female_psychology}
                          </p>
                        </div>
                        <p className="text-sm font-bold text-orange-900">
                          Tâm lý tính nữ
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* // {{-- Soul Plan Chart  --}} */}
              {soulPlan?.type === 6 ? (
                <div className="py-6">
                  <p className="text-lg font-bold text-[#000]">
                    <span>Soul Plan - Kế hoạch linh hồn</span>
                  </p>
                  <div className="mt-12 flex justify-between gap-2 py-2 pl-8">
                    <div className="flex w-2/3 items-center justify-start">
                      <div
                        className="relative min-h-[366px] min-w-[366px] bg-cover bg-center bg-no-repeat"
                        style={{ backgroundImage: `url(${Images.saosaucanh})` }}
                      >
                        <div className="absolute top-[52px] flex w-full items-center justify-center">
                          <SoulPlanIcons
                            soulPlan={soulPlan?.plan?.target_spirit}
                          />
                        </div>
                        <div className="absolute top-[-44px] flex w-full flex-col items-center text-center">
                          <p className="text-sm font-semibold text-purple-800">
                            Tinh thần mục tiêu
                          </p>
                          <p className="mt-0 text-[21px] font-bold text-purple-800">
                            {soulPlan?.plan?.target_spirit}
                          </p>
                        </div>

                        <div className="absolute left-[39px] top-[242px] flex w-[60px] items-center justify-center">
                          <SoulPlanIcons
                            soulPlan={soulPlan?.plan?.talent_spirit}
                          />
                          <div className="absolute  left-[-75px] top-[0px] flex w-[60px] flex-col items-center">
                            <p className="text-center text-sm font-semibold text-purple-800">
                              Tinh thần tài năng
                            </p>
                            <p className="mt-0 text-center text-[21px] font-bold text-purple-800">
                              {soulPlan?.plan?.talent_spirit}
                            </p>
                          </div>
                        </div>

                        <div className="absolute right-[42px] top-[242px] flex w-[60px] items-center justify-center">
                          <SoulPlanIcons
                            soulPlan={soulPlan?.plan?.challenging_spirit}
                          />
                          <div className="absolute right-[-80px] top-[0px]  flex w-[60px] flex-col items-center">
                            <p className="text-center text-sm font-semibold text-purple-800">
                              Tinh thần thử thách
                            </p>
                            <p className="mt-0 text-center text-[21px] font-bold text-purple-800">
                              {soulPlan?.plan?.challenging_spirit}
                            </p>
                          </div>
                        </div>

                        <div className="absolute top-[40%] flex w-full flex-col items-center justify-center">
                          <p className="text-center text-sm font-semibold text-blue-600">
                            Vận mệnh linh hồn
                          </p>
                          <p className="mx-auto text-[21px] font-bold text-blue-600">
                            {soulPlan?.soul_destiny}
                          </p>
                          <SoulPlanIcons soulPlan={soulPlan?.soul_destiny} />
                        </div>

                        <div className="absolute left-[39px] top-[96px] flex w-[60px] items-center justify-center">
                          <SoulPlanIcons
                            soulPlan={soulPlan?.plan?.target_material}
                          />
                          <div className="absolute left-[-75px] top-[-20px] w-[60px] text-center ">
                            <p className="text-center text-sm font-semibold text-orange-900">
                              Vật chất mục tiêu
                            </p>
                            <p className="mt-0 text-center text-[21px] font-bold text-orange-900">
                              {soulPlan?.plan?.target_material}
                            </p>
                          </div>
                        </div>

                        <div className="absolute right-[43px] top-[96px] flex w-[60px] items-center justify-center">
                          <SoulPlanIcons
                            soulPlan={soulPlan?.plan?.challenging_material}
                          />
                          <div className="absolute right-[-80px] top-[-20px] w-[60px] text-center">
                            <p className="text-center text-sm font-semibold text-orange-900">
                              Vật chất thử thách{" "}
                            </p>
                            <p className="mt-0 text-center text-[21px] font-bold text-orange-900">
                              {soulPlan?.plan?.challenging_material}
                            </p>
                          </div>
                        </div>

                        <div className="absolute bottom-[52px] flex w-full items-center justify-center">
                          <SoulPlanIcons
                            soulPlan={soulPlan?.plan?.talent_material}
                          />
                          <div className="absolute bottom-[-104px] flex w-full flex-col items-center justify-center">
                            <p className="text-center text-sm font-semibold text-orange-900">
                              Vật chất tài năng
                            </p>
                            <p className="mt-0 text-center text-[21px] font-bold text-orange-900">
                              {soulPlan?.plan?.talent_material}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex w-1/3 justify-center p-1 text-center">
                      <div
                        className="relative flex min-h-[150px] min-w-[140px] justify-center bg-contain bg-center bg-no-repeat"
                        // style={{
                        //   backgroundImage: `url(${Images.vatchatythuc})`,
                        // }}
                      >
                        <div className="relative top-[60px]">
                          {/* Hình tam giác ngược với cạnh trên màu xanh */}
                          <div
                            style={{
                              borderLeft: "50px solid transparent",
                              borderRight: "50px solid transparent",
                            }}
                            className="h-0 w-0 border-b-0 border-t-[120px] border-t-orange-900"
                          ></div>

                          {/* Hình tam giác với cạnh dưới màu xanh */}
                          <div
                            style={{
                              borderLeft: "50px solid transparent",
                              borderRight: "50px solid transparent",
                            }}
                            className="border-l-transparent border-r-transparent h-0 w-0 border-b-[120px] border-l-[50px] border-r-[50px] border-b-purple-800"
                          ></div>
                        </div>
                        <div className="absolute top-[34px] flex flex-col items-center justify-center text-center">
                          <p className="text-md  font-bold text-orange-900">
                            {lifeStage?.material_stage}
                          </p>
                          <p className="relative top-[4px] w-[60px] text-xs font-bold text-white">
                            1. Giai đoạn vật chất
                          </p>
                        </div>
                        <div className="absolute bottom-[30px] flex flex-col items-center justify-center text-center">
                          <p className="relative top-[-6px] w-[60px] text-xs font-bold text-white">
                            2. Giai đoạn tinh thần
                          </p>
                          <p className="text-md font-bold text-purple-800">
                            {lifeStage?.spirit_stage}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <p className="text-center text-sm font-bold text-blue-600">Đời sống</p> */}
                </div>
              ) : (
                <div className="py-6">
                  <p className="mb-2 text-lg font-bold  text-[#000]">
                    <span>Soul Plan - Kế hoạch linh hồn</span>
                  </p>

                  <div className="flex justify-between gap-2.5 py-12">
                    <div className="mt-4 flex w-2/3 items-center justify-start pl-[54px]">
                      <div
                        className="relative min-h-[300px] min-w-[300px] bg-contain bg-center"
                        style={{ backgroundImage: `url(${Images.tamgiac})` }}
                      >
                        <div className="flex justify-center">
                          <div className="absolute top-20">
                            <SoulPlanIcons
                              soulPlan={soulPlan?.plan?.target_material}
                            />
                          </div>
                          <div className="absolute top-[-30px] flex w-full flex-col items-center">
                            <p className="text-center text-sm font-semibold text-orange-900">
                              Mục tiêu
                            </p>
                            <p className="mt-0 text-center text-xl font-bold text-blue-600">
                              {soulPlan?.plan?.target_material}
                            </p>
                          </div>
                        </div>

                        <div className="flex items-center">
                          <div className="absolute bottom-[66px] right-[66px]">
                            <SoulPlanIcons
                              soulPlan={soulPlan?.plan?.challenging_material}
                            />
                          </div>
                          <div className="absolute bottom-0 right-[-180px] flex w-full flex-col items-center">
                            <p className="text-center text-sm font-semibold text-orange-900">
                              Thử thách
                            </p>
                            <p className="mt-0 text-center text-xl font-bold text-blue-600">
                              {soulPlan?.plan?.challenging_material}
                            </p>
                          </div>
                        </div>

                        <div className="flex items-center">
                          <div className="absolute bottom-[66px] left-[66px]">
                            <SoulPlanIcons
                              soulPlan={soulPlan?.plan?.talent_material}
                            />
                          </div>
                          <div className="absolute bottom-0 left-[-180px] flex w-full flex-col items-center">
                            <p className="text-center text-sm font-semibold text-orange-900">
                              Tài năng
                            </p>
                            <p className="mt-0 text-center text-xl font-bold text-blue-600">
                              {soulPlan?.plan?.talent_material}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex w-1/3 justify-center p-1 text-center">
                      <div
                        className="relative flex min-h-[150px] min-w-[150px] justify-center bg-contain bg-center bg-no-repeat"
                        // style={{
                        //   backgroundImage: `url(${Images.vatchatythuc})`,
                        // }}
                      >
                        <div className="relative top-[25px]">
                          {/* Hình tam giác ngược với cạnh trên màu xanh */}
                          <div
                            style={{
                              borderLeft: "50px solid transparent",
                              borderRight: "50px solid transparent",
                            }}
                            className="h-0 w-0 border-b-0 border-t-[120px] border-t-orange-900"
                          ></div>

                          {/* Hình tam giác với cạnh dưới màu xanh */}
                          <div
                            style={{
                              borderLeft: "50px solid transparent",
                              borderRight: "50px solid transparent",
                            }}
                            className="border-l-transparent border-r-transparent h-0 w-0 border-b-[120px] border-l-[50px] border-r-[50px] border-b-purple-800"
                          ></div>
                        </div>
                        <div className="absolute top-[0px] flex flex-col items-center justify-center text-center">
                          <p className="text-md  font-bold text-orange-900">
                            {lifeStage?.material_stage}
                          </p>
                          <p className="relative top-[4px] w-[60px] text-xs font-bold text-white">
                            1. Giai đoạn vật chất
                          </p>
                        </div>
                        <div className="absolute bottom-[10px] flex flex-col items-center justify-center text-center">
                          <p className="relative top-[-15px] w-[60px] text-xs font-bold text-white">
                            2. Giai đoạn tinh thần
                          </p>
                          <p className="text-md font-bold text-purple-800">
                            {lifeStage?.spirit_stage}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* <div className="mt-2 overflow-x-auto"> */}
              {/* <table className="border-black min-w-full border bg-white">
                <thead className="bg-sky-200">
                  <tr className="border-black h-[35px] border-b bg-primary">
                    <th
                      colSpan="6"
                      className="border-black border-r text-[14px] font-bold text-white"
                    >
                      Phân tích bản đồ tâm thức
                    </th>
                    <th className="border-black border-r text-[14px] font-bold text-white">
                      Lặp Lại NL
                    </th>
                    <th className="border-black border-r text-[14px] font-bold text-white">
                      Kết Hợp NL
                    </th>
                    <th className="text-[14px] font-bold text-white">Nổi Trội</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-black h-[35px] border-b">
                    <td
                      rowSpan="3"
                      className="border-black border-r bg-[#653A96] text-center font-bold text-white"
                    >
                      Thế giới vật chất
                    </td>
                    <td className="border-black border-r">Thử thách</td>
                    <SoulPlanIconTableDoubleCell
                      soulPlan={soulPlan?.plan?.challenging_material}
                    />
                    <td className="border-black w-[40px] border-r text-center">
                      {soulPlan?.plan?.challenging_material?.split("-")[0]}
                    </td>
                    <td className="border-black w-[40px] border-r text-center">
                      {soulPlan?.plan?.challenging_material?.split("-")[1]}
                    </td>
                    <td className="border-black border-r text-center">
                      <SoulPlanTickIconTable
                        soulPlan={soulPlan?.plan?.challenging_material}
                        tick="purple"
                        isBeforeNull={true}
                      />
                    </td>
                    <td className="border-black border-r text-center">
                      <SoulPlanTickIconTable
                        soulPlan={soulPlan?.plan?.challenging_material}
                        tick="purple"
                        isBeforeNull={false}
                      />
                    </td>
                    <td className="text-center">
                      {soulPlan?.outstanding_vibration ===
                      soulPlan?.plan?.challenging_material ? (
                        <ImageCheck source={Images.tickBlue} />
                      ) : null}
                    </td>
                  </tr>
                  <tr className="border-black h-[35px] border-b">
                    <td className="border-black border-r">Tài năng</td>
                    <SoulPlanIconTableDoubleCell
                      soulPlan={soulPlan?.plan?.talent_material}
                    />
                    <td className="border-black w-[40px] border-r text-center">
                      {soulPlan?.plan?.talent_material?.split("-")[0]}
                    </td>
                    <td className="border-black w-[40px] border-r text-center">
                      {soulPlan?.plan?.talent_material?.split("-")[1]}
                    </td>
                    <td className="border-black border-r text-center">
                      <SoulPlanTickIconTable
                        soulPlan={soulPlan?.plan?.talent_material}
                        tick="purple"
                        isBeforeNull={true}
                      />
                    </td>
                    <td className="border-black border-r text-center">
                      <SoulPlanTickIconTable
                        soulPlan={soulPlan?.plan?.talent_material}
                        tick="purple"
                        isBeforeNull={false}
                      />
                    </td>
                    <td className="text-center">
                      {soulPlan?.outstanding_vibration ===
                      soulPlan?.plan?.talent_material ? (
                        <ImageCheck source={Images.tickBlue} />
                      ) : null}
                    </td>
                  </tr>
                  <tr className="border-black h-[35px] border-b">
                    <td className="border-black border-r">Mục tiêu</td>
                    <SoulPlanIconTableDoubleCell
                      soulPlan={soulPlan?.plan?.target_material}
                    />
                    <td className="border-black w-[40px] border-r text-center">
                      {soulPlan?.plan?.target_material?.split("-")[0]}
                    </td>
                    <td className="border-black w-[40px] border-r text-center">
                      {soulPlan?.plan?.target_material?.split("-")[1]}
                    </td>
                    <td className="border-black border-r text-center">
                      <SoulPlanTickIconTable
                        soulPlan={soulPlan?.plan?.target_material}
                        tick="purple"
                        isBeforeNull={true}
                      />
                    </td>
                    <td className="border-black border-r text-center">
                      <SoulPlanTickIconTable
                        soulPlan={soulPlan?.plan?.target_material}
                        tick="purple"
                        isBeforeNull={false}
                      />
                    </td>
                    <td className="text-center">
                      {soulPlan?.outstanding_vibration ===
                      soulPlan?.plan?.target_material ? (
                        <ImageCheck source={Images.tickBlue} />
                      ) : null}
                    </td>
                  </tr>
                  <tr className="border-black h-[35px] border-b">
                    <td
                      rowSpan="3"
                      className="border-black border-r bg-primary text-center font-bold text-white"
                    >
                      Thế giới tinh thần
                    </td>
                    <td className="border-black border-r">Thử thách</td>

                    <SoulPlanIconTableDoubleCell
                      soulPlan={soulPlan?.plan?.challenging_spirit}
                    />
                    <td className="border-black w-[40px] border-r text-center">
                      {soulPlan?.plan?.challenging_spirit?.split("-")[0]}
                    </td>
                    <td className="border-black w-[40px] border-r text-center">
                      {soulPlan?.plan?.challenging_spirit?.split("-")[1]}
                    </td>
                    <td className="border-black border-r text-center">
                      <SoulPlanTickIconTable
                        soulPlan={soulPlan?.plan?.challenging_spirit}
                        tick="purple"
                        isBeforeNull={true}
                      />
                    </td>
                    <td className="border-black border-r text-center">
                      <SoulPlanTickIconTable
                        soulPlan={soulPlan?.plan?.challenging_spirit}
                        tick="purple"
                        isBeforeNull={false}
                      />
                    </td>
                    <td className="text-center">
                      {soulPlan?.outstanding_vibration ===
                      soulPlan?.plan?.challenging_spirit ? (
                        <ImageCheck source={Images.tickBlue} />
                      ) : null}
                    </td>
                  </tr>
                  <tr className="border-black h-[35px] border-b">
                    <td className="border-black border-r">Tài năng</td>

                    <SoulPlanIconTableDoubleCell
                      soulPlan={soulPlan?.plan?.talent_spirit}
                    />
                    <td className="border-black w-[40px] border-r text-center">
                      {soulPlan?.plan?.talent_spirit?.split("-")[0]}
                    </td>
                    <td className="border-black w-[40px] border-r text-center">
                      {soulPlan?.plan?.talent_spirit?.split("-")[1]}
                    </td>
                    <td className="border-black border-r text-center">
                      <SoulPlanTickIconTable
                        soulPlan={soulPlan?.plan?.talent_spirit}
                        tick="purple"
                        isBeforeNull={true}
                      />
                    </td>
                    <td className="border-black border-r text-center">
                      <SoulPlanTickIconTable
                        soulPlan={soulPlan?.plan?.talent_spirit}
                        tick="purple"
                        isBeforeNull={false}
                      />
                    </td>
                    <td className="text-center">
                      {soulPlan?.outstanding_vibration ===
                      soulPlan?.plan?.talent_spirit ? (
                        <ImageCheck source={Images.tickBlue} />
                      ) : null}
                    </td>
                  </tr>
                  <tr className="border-black h-[35px] border-b">
                    <td className="border-black border-r">Mục tiêu</td>

                    <SoulPlanIconTableDoubleCell
                      soulPlan={soulPlan?.plan?.target_spirit}
                    />
                    <td className="border-black w-[40px] border-r text-center">
                      {soulPlan?.plan?.target_spirit?.split("-")[0]}
                    </td>
                    <td className="border-black w-[40px] border-r text-center">
                      {soulPlan?.plan?.target_spirit?.split("-")[1]}
                    </td>
                    <td className="border-black border-r text-center">
                      <SoulPlanTickIconTable
                        soulPlan={soulPlan?.plan?.target_spirit}
                        tick="purple"
                        isBeforeNull={true}
                      />
                    </td>
                    <td className="border-black border-r text-center">
                      <SoulPlanTickIconTable
                        soulPlan={soulPlan?.plan?.target_spirit}
                        tick="purple"
                        isBeforeNull={false}
                      />
                    </td>
                    <td className="text-center">
                      {soulPlan?.outstanding_vibration ===
                      soulPlan?.plan?.target_spirit ? (
                        <ImageCheck source={Images.tickBlue} />
                      ) : null}
                    </td>
                  </tr>
                  <tr className="h-[35px]">
                    <td
                      colSpan="2"
                      className="border-black border-r bg-[#1B4791] text-center font-bold text-white"
                    >
                      Vận mệnh linh hồn
                    </td>
                    <SoulPlanIconTableDoubleCell
                      soulPlan={soulPlan?.soul_destiny}
                    />

                    <td className="border-black w-[40px] border-r text-center">
                      {soulPlan?.soul_destiny?.split("-")[0]}
                    </td>
                    <td className="border-black w-[40px] border-r text-center">
                      {soulPlan?.soul_destiny?.split("-")[1]}
                    </td>
                    <td className="border-black border-r text-center">
                      <SoulPlanTickIconTable
                        soulPlan={soulPlan?.soul_destiny}
                        tick="purple"
                        isBeforeNull={true}
                      />
                    </td>
                    <td className="border-black border-r text-center">
                      <SoulPlanTickIconTable
                        soulPlan={soulPlan?.soul_destiny}
                        tick="purple"
                        isBeforeNull={false}
                      />
                    </td>
                    <td className="text-center">
                      {soulPlan?.outstanding_vibration ===
                      soulPlan?.soul_destiny ? (
                        <img
                          src={Images.tickBlue}
                          className="mx-auto w-[15px]"
                          alt="tick blue"
                        />
                      ) : null}
                    </td>
                  </tr>
                </tbody>
              </table> */}
              {/* </div> --}} */}
            </div>
          </div>

          {/* {{-- footer --}} */}
          <footer
            className="flex h-[110px] w-full items-center justify-center bg-cover bg-no-repeat py-10"
            style={{ backgroundImage: `url(${Images.footer}` }}
          >
            <h2
              className="text-5xl font-bold  text-[#000]"
              style={{ fontFamily: "Dancing Script" }}
            >
              Thank You
            </h2>
          </footer>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="outlined"
          className="border-solid border-primaryPurple text-primaryPurple"
        >
          Đóng
        </Button>
        <Button
          onClick={(e) => handleDownloadPdf(data?.data.id)}
          className="bg-primaryPurple text-white"
        >
          Tải xuống
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogReport;
