import axios from "axios";

import { endPoint } from "configs/env";
import { AUTHENTICATION } from "constant/AuthConstant";
import { getItemFromCookieStorage } from "utils/cookie";

export const fetchClient = (isToken, isFile = false) => {
  const token = getItemFromCookieStorage(AUTHENTICATION);

  const configHeader = {
    "Content-type": isFile
      ? "multipart/form-data"
      : "application/json; charset=utf-8",
  };

  const defaultConfig = {
    baseURL:
      endPoint[process.env.NODE_ENV] ||
      "https://be.cicinhakhanh.com/api",
      // "http://localhost:8000/api",

    headers: configHeader,
  };

  if (isToken) {
    defaultConfig.headers.Authorization = `Bearer ${token}`;
  }

  const instance = axios.create(defaultConfig);

  // Handle refresh token
  instance.interceptors.response.use(
    (res) => res,
    async (err) => {
      // You can implement token refresh logic here
      return Promise.reject(err);
    }
  );

  return instance;
};
