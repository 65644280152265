/* eslint-disable no-unused-expressions */
"use client";

import * as React from "react";

// import html2canvas from 'html2canvas'
import jsPDF from "jspdf";

// import html2pdf from 'html2pdf.js'
import html2canvas from "html2canvas-pro";

// MUI Imports
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";

import { Box, Divider } from "@mui/material";
import { formattedDate } from "utils/helpers";
import { Download, Input, OpenInNew } from "@mui/icons-material";
import { USER_INFO } from "constant/AuthConstant";
import { getFromLocalStorage } from "utils/local-storage";
import DialogReport from "./DialogReport";
// import { useNavigate } from "react-router-dom";
import { setLocalStorage } from "utils/local-storage";
import { PDF_DATA } from "constant/AuthConstant";
import { modifiedName } from "utils/helpers";

const CardStudent = (data) => {
  const {
    fullName,
    birthday,
    gender,
    creator,
    created_at,
    // awareness,
    // lifeStage,
    // soulPlan,
  } = data.data;

  const matrixData = data.data;

  const [open, setOpen] = React.useState(false);
  const user_info = getFromLocalStorage(USER_INFO);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Hàm chuyển đổi URL ảnh sang Base64

  const handleDownloadPdf = async (id) => {
    const pdf = new jsPDF("l", "mm", "a4");

    // Xử lý trang 1
    const page1Element = document.getElementById(`pdf_page_1_${data?.data.id}`);
    if (page1Element) {
      const canvas1 = await html2canvas(page1Element, {
        scale: 8, // Tăng scale để cải thiện chất lượng
        useCORS: true, // Hỗ trợ tải ảnh từ các nguồn khác
      });
      const imgData1 = canvas1.toDataURL("image/jpeg", 1.0); // Chất lượng tối đa
      const imgWidth = 297; // Chiều rộng A4
      const imgHeight = (canvas1.height * imgWidth) / canvas1.width;

      pdf.addImage(imgData1, "JPEG", 0, 0, imgWidth, imgHeight);
    }

    // Xử lý trang 2
    const page2Element = document.getElementById(`pdf_page_2_${data?.data.id}`);
    if (page2Element) {
      pdf.addPage(); // Thêm trang mới
      const canvas2 = await html2canvas(page2Element, {
        scale: 8, // Tăng scale để cải thiện chất lượng
        useCORS: true, // Hỗ trợ tải ảnh từ các nguồn khác
      });
      const imgData2 = canvas2.toDataURL("image/jpeg", 1.0); // Chất lượng tối đa
      const imgWidth = 297; // Chiều rộng A4
      const imgHeight = (canvas2.height * imgWidth) / canvas2.width;

      pdf.addImage(imgData2, "JPEG", 0, 0, imgWidth, imgHeight);
    }

    const nameEdited = modifiedName(
      matrixData?.matrixes?.fullName || matrixData?.fullName
    );

    // Xuất PDF
    pdf.save(`${nameEdited}_matrix_report.pdf`);
  };

  const handleNavigateToPreview = () => {
    setLocalStorage(PDF_DATA, data);
    window.open("/matrix/view?nodownload=true", "_blank");
  };
  return (
    <>
      <Card>
        <Box>
          <Box className="flex items-center justify-between bg-purple-100 p-3">
            <Typography className="mb-1 text-[14px] font-semibold capitalize text-primary">
              <strong>
                <OpenInNew onClick={handleClickOpen} />{" "}
                {matrixData?.matrixes?.fullName || matrixData?.fullName}
              </strong>
            </Typography>
            <Typography className="mb-1 text-[14px] font-semibold text-primary">
              <strong> {birthday}</strong>
            </Typography>
          </Box>
          {/* <Divider className="my-6" sx={{ marginY: "10px" }} /> */}
          <Box className="p-2">
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Typography className="mb-1 text-[14px] font-semibold">
                  <strong className="text-grey">Họ tên:</strong>
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className="mb-1 text-[14px] font-semibold">
                  {matrixData?.matrixes?.fullName || matrixData?.fullName}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Typography className="mb-1 text-[14px] font-semibold">
                  <strong className="text-grey">Ngày sinh</strong>
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className="mb-1 text-[14px] font-semibold">
                  {birthday}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Typography className="mb-1 text-[14px] font-semibold">
                  <strong className="text-grey">Giới tính:</strong>
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className="mb-1 text-[14px] font-semibold">
                  {" "}
                  {gender === "male" ? "Nam" : "Nữ"}
                </Typography>
              </Grid>
            </Grid>
            <Divider className="my-3" sx={{ marginY: "10px" }} />
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Typography className="mb-1 text-[14px] font-semibold">
                  <strong className="text-grey">Người tạo:</strong>
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className="mb-1 text-[14px] font-semibold">
                  {creator?.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Typography className="mb-1 text-[14px] font-semibold">
                  <strong className="text-grey">Ngày tạo:</strong>
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className="mb-1 text-[14px] font-semibold">
                  {" "}
                  {formattedDate(created_at)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Divider className="" />
        <CardActions className="card-actions-dense">
          <Button
            className="normal-case text-primary"
            onClick={handleClickOpen}
            // href={`${viewReport}/${data.data.id}`}
            target="_blank"
            sx={{ color: "#a881fe" }}
          >
            {/* <PreviewIcon /> */}
            <Input sx={{ mr: "5px", fontSize: "1rem" }} /> Xem báo cáo
          </Button>
          <Button
            onClick={handleNavigateToPreview}
            // href={`${exportReport}/${data.data.id}`}
            className="normal-case text-primary"
          >
            <Download sx={{ mr: "5px", fontSize: "1rem" }} /> Tải về máy
          </Button>
        </CardActions>
      </Card>

      {/* Render the DownloadReport component in a hidden div */}
      {/* <div style={{ display: "none" }}>
        <DownloadReport data={data} user_info={user_info} />
      </div> */}
      <DialogReport
        open={open}
        handleClose={handleClose}
        user_info={user_info}
        data={data}
        handleDownloadPdf={handleDownloadPdf}
      />
    </>
  );
};

export default CardStudent;
