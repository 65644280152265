import { USER_INFO } from "constant/AuthConstant";
import { AUTHENTICATION } from "constant/AuthConstant";
import React from "react";
import { Navigate } from "react-router-dom";
import { getItemFromCookieStorage } from "utils/cookie";
import { getFromLocalStorage } from "utils/local-storage";

const PrivateRoute = ({ element }) => {
  const isAuthenticated = !!getItemFromCookieStorage(AUTHENTICATION);
  const userInfo = getFromLocalStorage(USER_INFO)?.user;
  const isActive = userInfo.active;

  return isAuthenticated && isActive ? (
    element
  ) : (
    <Navigate to="/auth/sign-in" />
  );
};

export default PrivateRoute;
