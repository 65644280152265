import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";

// Icon Imports
import SoulPlan from "views/admin/soul-plan";
import UserTable from "views/admin/user";
import Images from "./Images";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Matrix from "views/admin/matrix";
import {
  MATRIX_OF_DESTINY,
  SOUL_PLAN_GROUP,
  SOUL_PLAN_PERSONAL,
} from "./LevelConstant";
import { USER_INFO } from "./AuthConstant";

export const MenuItems = [
  {
    name: "Thông tin người dùng",
    layout: "/admin",
    path: "default",
    icon: (
      <ContactPageIcon className="h-6 w-6 text-primaryPurple" alt="six star" />
    ),
    component: <MainDashboard />,
  },
  {
    name: "Soul Plan Personal",
    layout: "/admin",
    icon: (
      <img className="h-6 w-6" src={Images.iconSixPointedStar} alt="six star" />
    ),
    path: "soul-plan",
    component: <SoulPlan />,
  },
  {
    name: "Matrix Of Destiny",
    layout: "/admin",
    icon: <img className="h-6 w-6" src={Images.matrix} alt="six star" />,
    path: "matrix",
    component: <Matrix />,
  },
  {
    name: "Danh sách người dùng",
    layout: "/admin",
    icon: (
      <AccountCircleIcon
        className="h-6 w-6 text-primaryPurple"
        alt="six star"
      />
    ),
    path: "user",
    component: <UserTable />,
  },
];

export const MenuItemsUser = [
  {
    name: "Thông tin người dùng",
    layout: "/admin",
    path: "default",
    icon: (
      <ContactPageIcon className="h-6 w-6 text-primaryPurple" alt="six star" />
    ),
    component: <MainDashboard />,
    key: USER_INFO,
  },
  {
    name: "Soul Plan Personal",
    layout: "/admin",
    icon: <img className="h-6 w-6" src={Images.matrix} alt="six star" />,
    path: "soul-plan",
    component: <SoulPlan />,
    key: SOUL_PLAN_PERSONAL,
  },
  {
    name: "Matrix Of Destiny",
    layout: "/admin",
    icon: <img className="h-6 w-6" src={Images.matrix} alt="six star" />,
    path: "matrix",
    component: <Matrix />,
    key: MATRIX_OF_DESTINY,
  },
];

export default MenuItems;
