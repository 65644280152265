import { AUTHENTICATION } from "constant/AuthConstant";
import { USER_INFO } from "constant/AuthConstant";
import { setUser } from "store/userReducer";
import { saveToCookieStorage } from "utils/cookie";
import { setLocalStorage } from "utils/local-storage";

// utils/userStorageHelper.js
export const handleUserData = (userData, dispatch) => {
  dispatch(setUser(userData.user));
  setLocalStorage(USER_INFO, userData);
  saveToCookieStorage(AUTHENTICATION, userData.access_token);
};
